import { Environment } from './types';

export const environment: Environment = {
  production: true,
  featureSet: 'default',
  showErrorTraces: false,
  oauthBaseUrl: 'https://auth.fcgapps.com',
  oauthClientId: '32q7dtf26ild13o5llq0u1gcvv',
  oauthScopes: 'openid+email',
  apiBaseUrl: 'https://1587jrfw9c.execute-api.eu-north-1.amazonaws.com/Prod',
  documentationApiUrl:
    'https://atlk65auk3.execute-api.eu-north-1.amazonaws.com/documentation/oasys',
  notificationApiBaseUrl:
    'https://11jkme0tu4.execute-api.eu-north-1.amazonaws.com',
};
